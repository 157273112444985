export const getSortedDictionaries = (dictionaries, dicName) => {
  const firstGroup = [
    'app_close_reason',
    'app_status',
    'app_subject',
    'awareness_source',
    'education',
    'marital_status',
    'person_category',
    'region',
    'regularity_of_assistance',
    'subway_station',
  ];

  const secondGroup = [
    'address_type',
    'app_type',
    'auth_role',
    'contract_status',
    'person_status',
    'person_type',
    'phone_type',
  ];

  // Если словарь относится к первой группе
  if (firstGroup.includes(dicName)) {
    return [...dictionaries]
      .sort((a, b) => a.name.localeCompare(b.name)) // Сортировка по имени (по алфавиту)
      .sort((a, b) => {
        if (a.dsc && !b.dsc) return -1; // С описанием выше
        if (!a.dsc && b.dsc) return 1;
        return 0;
      })
      .sort((a, b) => a.deleted - b.deleted); // Удаленные в конце
  }

  // Если словарь относится ко второй группе
  if (secondGroup.includes(dicName)) {
    return [...dictionaries]
      .sort((a, b) => a.id - b.id) // Сортировка по ID
      .sort((a, b) => a.deleted - b.deleted); // Удаленные в конце
  }

  // Если сортировка не требуется (третья группа)
  return dictionaries;
};
